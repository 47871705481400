import React from 'react';
import { Typography } from '@material-ui/core';
import Layout from '../../../components/layout';

function CheckoutSuccess() {
  return (
    <Layout>
      <Typography variant='h5' gutterBottom>
        Thank you for your subscription.
      </Typography>
    </Layout>
  );
}

export default CheckoutSuccess;
